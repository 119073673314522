import { render, staticRenderFns } from "./default.vue?vue&type=template&id=8b6b9800"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsNotifications: require('/Users/stefan/Dropbox/Stefan/gitrepos/yes/nachhilfe-app/components/modals/Notifications.vue').default,ModalsTicketUebersicht: require('/Users/stefan/Dropbox/Stefan/gitrepos/yes/nachhilfe-app/components/modals/TicketUebersicht.vue').default})
