export default function({$auth, route, redirect}) {
  let perArray = [
    // {route: 'intern-projekte', permission: 'Projekte verwalten'},
    // {route: 'intern-statistik', permission: 'Statistik sehen'},
  ]

  let perm = perArray.find(el => el.route == route.name)
  let neededPermission = (perm) ? perm.permission : '';

  if (!neededPermission) return true;
  let can = $auth.user.allePermissions.find(el => el.name == neededPermission);
  if (can) return true;
  console.log('Zugriff verweigert. Nicht die entsprechende Permission '+neededPermission);
  return redirect('/dashboard');

}
