
export default {
  props: [],
  data() {
    return {
      showSidebar: false,
      ticket: null,
      params: {
        unsichtbar: ['theta', 'tab', 'strike', 'code', 'paragraph', 'h1', 'h2', 'h3', 'code_block', 'hr', 'table', 'bilder']
      }
    }
  },
  methods: {
    open(params) {
      if (this.showSidebar) return;
      this.ticket = {
        titel: null,
        text: null,
        anliegen: false,
        bearbeiter: null,
      };
      if (params && params.ansprechpartner_id) {
        this.ticket.bearbeiter = [params.ansprechpartner_id];
      }
      this.showSidebar = true;
    },
    speichern() {
      this.$$store('app/tickets', this.ticket).then(response => {
        this.done();
        this.ticket = null;
      })
    },
    done() {
      let titel = "Dein Anliegen ist eingegangen.";
      let text = "Wir melden uns bei dir!";
      this.$router.push({path: '/intern/beendeter-vorgang', query: {titel: titel, text: text}})
    }
  },
  mounted() {
    this.$nuxt.$on('create-ticket', this.open);
    this.$nuxt.$on('closeSidebars', () => {this.showSidebar = false});
  },
  beforeDestroy() {
    this.$nuxt.$off('create-ticket', this.open);
    this.$nuxt.$off('closeSidebars', () => {this.showSidebar = false});
  },
}
