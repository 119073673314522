
export default {
  props: [],
  data() {
    return {
      showSidebar: false,
    }
  },
  methods: {
    open() {
      if (this.showSidebar) return;
      this.showSidebar = true;
      this.abrufen();
    },
    abrufen() {
      this.$$abrufen('app/tickets')
    },
  },
  mounted() {
    this.$nuxt.$on('display-tickets', this.open);
    this.$nuxt.$on('closeSidebars', () => {this.showSidebar = false});
  },
  beforeDestroy() {
    this.$nuxt.$off('display-tickets', this.open);
    this.$nuxt.$off('closeSidebars', () => {this.showSidebar = false});
  },
}
