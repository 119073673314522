import Vue from 'vue';
import { Capacitor } from '@capacitor/core';

let helpers = {
  methods: {
    $round(number, digits = 2) {
      return Math.round(number * Math.pow(10,digits)) / Math.pow(10,digits);
    },
    $textColorErrechnet(farbe) {
      if (!farbe) { return ''; }
      return (parseInt(farbe.replace('#', ''), 16) > 0xffffff / 2) ? 'text-dark' : 'text-light';
  
    }
  },
  computed: {
    $appVersion() {
      return process.env.APP_VERSION+' #'+process.env.buildnumber;
    },
    $dev() {
      if (process.env.STAGE == 'dev') return true;
      return false;
    },
    $staging() {
      if (process.env.STAGE == 'staging') return true;
      return false;
    },
    $production() {
      if (process.env.STAGE == 'production') return true;
      return false;
    },
    
    $isWeb() {
      return !Capacitor.isNativePlatform();
    },
    $isNative() {
      return Capacitor.isNativePlatform();
    },
    $platform() {
      return Capacitor.getPlatform();
    },
  }
}
Vue.mixin(helpers);
export default helpers;

/**
 * Alle Nicht Gesundheitsportal spezifischen Helper wurden ausgegliedert in das p1ng Quickstart Package und können/dürfen da ergänzt werden.
 */
