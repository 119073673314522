import { render, staticRenderFns } from "./LoadingBar.vue?vue&type=template&id=7a8d9ae3&scoped=true"
import script from "./LoadingBar.vue?vue&type=script&lang=js"
export * from "./LoadingBar.vue?vue&type=script&lang=js"
import style0 from "./LoadingBar.vue?vue&type=style&index=0&id=7a8d9ae3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a8d9ae3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DisplayLogoSpin: require('/Users/stefan/Dropbox/Stefan/gitrepos/yes/nachhilfe-app/components/global/display/LogoSpin.vue').default})
