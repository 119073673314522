
export default {
    mixins: [],
    props: [],
    data() {
        return {}
    },
    computed: {},
    watch: {},
    methods: {},
    mounted() {},

}
