export const state = () => ({
  entschuldigteStunden: []
})

const getters = {
  getEntschuldigt(state) {
    return state.entschuldigteStunden;
  }
}

const mutations = {
  setEntschuldigt(state, block) {
    let element = {
      gueltig_bis: this.$moment(block.datum).add(4, 'days').format('YYYY-MM-DD'),
      block_id: block.id,
    }
    state.entschuldigteStunden.push(element);
  },
  removeAbgelaufen(state) {
    state.entschuldigteStunden = state.entschuldigteStunden.filter(el => {
      return el.gueltig_bis >= this.$moment().format('YYYY-MM-DD');
    })
  },
  removeOne(state, block) {
    state.entschuldigteStunden = state.entschuldigteStunden.filter(el => {
      return el.block_id != block.id;
    })
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
