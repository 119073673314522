
import { Keyboard } from '@capacitor/keyboard';

export default {
    mixins: [],
    props: {
        noheader: {
            default: null
        },
        title: {
            default: null
        },
        noswipe: {
            default: false
        }
    },
    data() {
        return {
            menuepunkte: [
                {'name': 'Start', 'icon': 'home', 'url': '/dashboard'},
                {'name': 'Stunden', 'icon': 'calendar-outline', 'url': '/intern/stunden'},
                {'name': 'Urlaub', 'icon': 'airplane', 'url': '/intern/urlaub'},
                {'name': 'Krank', 'icon': 'eyedrop', 'url': '/intern/krankmeldung'},
                {'name': 'Menü', 'icon': 'menu', 'url': '/intern/menue'},
            ],
            headerHeight: 0,
            displayNav: true,
        }
    },
    computed: {
        path() {
            return this.$route.path;
        },
        backbutton() {
            return (this.$listeners && this.$listeners.back);
        },
        unreadNotifications() {
            return this.$store.state.notifications.unreadNotifications
        }
    },
    watch: {},
    methods: {
        setHeaderHeight() {
            if (this.noheader !== null) return;
            this.$nextTick(() => {
                if (!this.$refs.header) return this.setHeaderHeight();
                this.headerHeight = this.$refs.header.clientHeight;
            }, 50)
        },
        navClick(punkt) {
            this.$router.push(punkt.url)
            this.$nuxt.$emit('closeSidebars');
        },
        swipeBack() {
          if (this.noswipe == false) this.$emit('back');
        },
        pushnotificationVerarbeiten(event) {
            if (Object.values(event)) {
                this.$router.push('/intern/stunden');
                this.$nuxt.$emit('create-stunde', event.block)
            }
            else {
                // console.log('ELSE');
            }
            // console.log(event);
        }
    },
    mounted() {
        this.setHeaderHeight();

        Keyboard.addListener('keyboardDidShow', info => {
            this.displayNav = false;
        });

        Keyboard.addListener('keyboardWillHide', () => {
            this.displayNav = true;
        });


        this.$nuxt.$on('pushnotification-click', this.pushnotificationVerarbeiten)
    },
    beforeDestroy() {
        this.$nuxt.$off('pushnotification-click', this.pushnotificationVerarbeiten)
    }

}
