
export default {
    mixins: [],
    props: ['ticket'],
    data() {
        return {
            inhaltAnzeigen: false,
        }
    },
    computed: {},
    watch: {},
    methods: {
        followLink() {
            let token = this.$auth.strategy.token.get().replace('Bearer ', '');
            window.open(process.env.API_URL+'/tickets/'+this.ticket.nummer+'?token='+token, '_blank');
        }
    },
    mounted() {},

}
