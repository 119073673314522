
export default {
    mixins: [],
    props: [],
    data() {
        return {
            loading: false,
        }
    },
    computed: {},
    watch: {},
    methods: {
        start() {
            this.loading = true
        },
        finish() {
            this.loading = false
        },
    },
    mounted() {},

}
