export const state = () => ({
    unreadNotifications: 0
  })
  
  const getters = {
    getUnread(state) {
      return state.unreadNotifications;
    }
  }
  
  const mutations = {
    setUnread(state, unreadNotifications) {
      state.unreadNotifications = unreadNotifications;
    }
  }
  const actions = {
  
  }
  
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }
  