
export default {
    mixins: [],
    props: [],
    data() {
        return {}
    },
    computed: {},
    watch: {},
    methods: {
        splashScreen() {
            if (!process.env.SPLASHSCREENTIME) return;
            this.$nextTick(() => {
                this.$nuxt.$loading.start()
                setTimeout(() => {
                    this.$nuxt.$loading.finish();
                }, process.env.SPLASHSCREENTIME)
            });
        },
        notificationsAbrufen() {
            this.$axios.get('/api/benachrichtigungen?page='+this.page).then(response => {
                this.$store.commit('notifications/setUnread', response.data.unread);
            })
        },
    },
    mounted() {
        if (this.$auth.loggedIn) {
            this.notificationsAbrufen();
        }
        else {
            this.$nuxt.$on('login', this.notificationsAbrufen)
        }
        this.$nuxt.$on('update-notifications', this.notificationsAbrufen);
        this.splashScreen();
    },
    beforeDestroy() {
        this.$nuxt.$off('login', this.notificationsAbrufen);
        this.$nuxt.$off('update-notifications', this.notificationsAbrufen);
    }

}
