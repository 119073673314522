
export default {
  props: [],
  data() {
    return {
      showSidebar: false,
      notifications: [],
      'hasMore': true,
      'noMore': false,
      'page' : 1,
      swipe: 'none',
    }
  },
  methods: {
    open(info) {
      if (this.showSidebar) return;
      this.info = info;
      this.abrufen(true);
      this.showSidebar = true;
    },
    abrufen(reset = null) {
      if (reset) {
        this.hasMore = true; 
        this.page = 1;
        this.notifications = [];
      }
      if (this.hasMore) {
            this.hasMore = false;
            this.$axios.get("/api/benachrichtigungen?page="+this.page).then(response => {
                this.notifications = this.notifications.concat(response.data.messages);
                this.hasMore = response.data.hasMore;
                this.$store.commit('notifications/setUnread', response.data.unread);
                if (this.hasMore == false) this.noMore = true;
                this.page++;
                if (this.hasMore && this.page % 3 != 0) this.abrufen();
            });
        }
    },
    weiterladen() {
      this.abrufen();
    },
    onScroll: function({ target: { scrollTop, clientHeight, scrollHeight }}) {
        if (scrollTop + clientHeight >= scrollHeight-300) {
            this.abrufen()
        }
    },
    swipeHandler() {
      this.swipe = 'left'
    },
    async changeRead(benachrichtigung) {
        if (benachrichtigung.read) {
            benachrichtigung.read = false;
            await this.$axios.put('/api/benachrichtigungen/'+benachrichtigung.id+'/unread');
        }
        else {
            benachrichtigung.read = true;
            await this.$axios.put('/api/benachrichtigungen/'+benachrichtigung.id+'/read');
        }
        this.$nuxt.$emit('update-notifications');

    },
    followLink(benachrichtigung) {
        benachrichtigung.read = true;
        let token = this.$auth.strategy.token.get().replace('Bearer ', '');
        if (benachrichtigung.link) window.open(process.env.API_URL+'/notifications/'+benachrichtigung.id+'?token='+token, '_blank');
        setTimeout(() => {
          this.$nuxt.$emit('update-notifications');
        }, 500);
    }
  },
  mounted() {
    this.$nuxt.$on('display-notifications', this.open);
    this.$nuxt.$on('closeSidebars', () => {this.showSidebar = false});
    // window.addEventListener('scroll', this.onScroll);

  },
  beforeDestroy() {
    this.$nuxt.$off('display-notifications', this.open);
    this.$nuxt.$off('closeSidebars', () => {this.showSidebar = false});
  },
}
